// @flow
// Util method to determine app's environment

type CMEnv = 'PROD' | 'DEV' | 'TEST';

export default class CMEnvironment {

  static isTest(): boolean {
    return process.env.JEST_WORKER_ID !== undefined;
  }

  static isDev(): boolean {
    const hostname: string = window.location.hostname ?? "";
    return (
      hostname.includes("dev.") 
      || hostname.includes("deploy-preview") 
      || hostname === "localhost" 
      || hostname === ""
    );
  }

  static isProd(): boolean {
    return !this.isDev() && !this.isTest();
  }

  static getEnviroment(): CMEnv {
    if (this.isTest()) {
     return 'TEST';
    }
    else if (this.isDev()) {
     return 'DEV';
    }
    else {
      return 'PROD';
    }
  } 
}
