// @flow

import * as React from "react";
import axios from "axios";
import { useCallback, useContext, useEffect, useState } from "react";
import { CMContext } from "../../provider";
import { useCMModal } from "../utils/customHooks";
import {
  api,
  CMApiCallJobData,
  getCMApiCallJobData,
  useColorMotiveAPI,
} from "../utils/colormotive_api_calls";
import { isValidEmail, isValidName, isValidPhone } from "../utils/formUtils";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
  useMediaQuery
} from "@material-ui/core";
import CMHeader from "../components/header";
import CMImage from "../components/image";
import LocalStorage from "../LocalStorage";
import theme from '../theme';
import { ThemeProvider } from '@material-ui/core/styles';
import GAEventLogger, {
  GAEventActions,
  GAEventCategories,
} from "../utils/google_analytics/GAEventLogger";
import HubSpotLogger from '../utils/hubspot/HubSpotLogger';
import {useCMToHubSpotConnector} from '../utils/hubspot/useCMToHubSpotConnector';
import Head from '../components/head';

function enough(num,threshold,cssClass) {
  if (num > threshold) {
    return cssClass;
  }
}

type Props = {
  path: string,
};

export type CMLeadInfo = {
  emailAddress?: string,
  firstName?: string,
  lastName?: string,
  phoneNumber?: string,
};

const defaultFormValues: CMLeadInfo = {
  emailAddress: "",
  firstName: "",
  lastName: "",
  phoneNumber: "",
};

/* the order of the formConfig is the order in which the inputs are rendered */
const formConfig = {
  firstName: {
    isValid: isValidName,
    label: "Please enter a first name",
    placeHolder: "First Name",
  },
  lastName: {
    isValid: isValidName,
    label: "Please enter a last name",
    placeHolder: "Last Name",
  },
  phoneNumber: {
    isValid: (phoneNumber: string): boolean =>
      phoneNumber === "" || isValidPhone(phoneNumber),
    label: "Please enter a phone number",
    placeHolder: "Phone Number",
  },
  emailAddress: {
    isValid: isValidEmail,
    label: "Please enter a valid email address",
    placeHolder: "Email Address",
  },
};
const maxContentWidth = 660;
const maxPageWidth = 1177;
const sideMargins = 18;

const Results = (): React.Node => {

  const useStyles = makeStyles((theme) => ({
    root: {
      marginTop: '110px',
      [theme.breakpoints.up('md')]: {
        marginTop: '158px',
      },
    },
    adjectives: {
      marginBottom: '32px',
      [theme.breakpoints.up('md')]: {
        marginBottom: '48px',
      },
    },
    altSection: {
      background: '#F4F4F4',
      padding: '44px 0 68px',
      maxWidth: '100%',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        padding: '120px 0 150px',
      },
    },
    bottomSpacing: {
      marginBottom: '16px',
    },
    caption: {
      padding: '5px 24px',
    },
    centerMobileOnly: {
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'initial',
      },
    },
    cta: {
      background: '#F4F4F4',
      margin: '0 auto',
      maxWidth: maxContentWidth + 'px',
      padding: '0 ' + sideMargins + 'px',
      [theme.breakpoints.up('md')]: {
        maxWidth: maxPageWidth + 'px',
      },
    },
    fields: {
      marginTop: '50px',
      [theme.breakpoints.up('md')]: {
        marginTop: '33px',
      },
    },
    form: {
      [theme.breakpoints.up('md')]: {
        marginLeft: '95px',
      },
    },
    header: {
      boxShadow: 'none',
      padding: '44px 0 34px',
    },
    match: {
      margin: '0 0 24px',
    },
    pageWidth: {
      maxWidth: maxContentWidth + 'px',
      padding: '0 ' + sideMargins + 'px',
    },
    palette: {
      borderRadius: '5px',
      justifyContent: 'center',
      margin: '22px auto',
      maxWidth: '198px',
      overflow: 'hidden',
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
        maxWidth: 'none',
      },
    },
    rule: {
      border: 'none',
      borderTop: '1px solid #CACACA',
      margin: '50px 0',
      [theme.breakpoints.up('md')]: {
        margin: '80px 0 54px',
      },
    },
    screenshot: {
      boxShadow: '0px 2px 2px 0px rgb(0 0 0 / 60%)',
      borderBottom: '1px solid #CACACA',
      margin: '48px 0 0',
      [theme.breakpoints.up('md')]: {
        boxShadow: '2px 6px 10px rgba(0 0 0 / 20%)',
      },
    },
    similarities: {
      marginBottom: '67px',
    },
    similarity: {
      margin: 'auto',
      maxWidth: '198px',
      [theme.breakpoints.up('sm')]: {
        margin: '0',
      },
    },
    subHeading: {
      margin: '67px 0 16px',
    },
    swatchBright: {
      border: '1px solid rgb(86 80 80 / 70%)'
    },
    swatchColor: {
      boxShadow: '2px 4px 10px rgb(0 0 0 / 15%)',
      borderRadius: '10px',
      display: 'block',
      height: '55px',
      overflow: 'hidden',
      margin: '6px 0',
      width: '55px',
    },
    swatchNum: {
      color: '#565050',
      margin: '14px auto 20px',
    },
    getReportButton: {
      backgroundColor: '#e68943',
      color: '#ffffff',
    },
  }));
  const classes = useStyles();

  const jobID = useContext(CMContext).state.cm_api_info.job_id ?? LocalStorage.get();
  const [report, setReport] = useState(null);
  const overall_similarity = report?.overall_sim ?? 0;
  const similarity_percentage = Math.round(overall_similarity * 100);

  const { CMModal, handleModalOpen } = useCMModal();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [screenshot, setScreenshot] = useState(null);
  const [websiteURL, setWebsiteURL] = useState(null);
  const [colorPalette, setColorPalette] = useState(null);
  const [matches, setMatches] = useState(null);
  const [similarPalettes, setSimilarPalettes] = useState(null);

  const [formValues, setFormValues] = useState<CMLeadInfo>(defaultFormValues);
  const [formErrors, setFormErrors] = useState<CMLeadInfo>({});
  const [emailMessage, setEmailMessage] = useState<?string>(undefined);

  const { emailAddress } = formValues;
  const formErrorKeys = Object.keys(formErrors);
  const hasValidationError = formErrorKeys.length > 0;

  const {hubSpotData} = useCMToHubSpotConnector(formValues);

  const postEmail = useCallback(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.EMAIL, "post", {
        to_email: emailAddress,
      }),
    [emailAddress, jobID],
  );

  const { isLoading: isScreenshotLoading } = useColorMotiveAPI(
    () => getCMApiCallJobData(jobID, CMApiCallJobData.SCREENSHOT),
    false,
    true,
    // update the site analysis progress on complete
    (response) => {
      setScreenshot(response?.data?.screenshot ?? "");
    },
    // (error) => {console.log(error);},  // helpful for debugging
  );

  const { isLoading: isURLLoading } = useColorMotiveAPI(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.URL),
      false,
      true,
      (response) => setWebsiteURL(response?.data?.url ?? ""),
  );

  const { isLoading: isColorLoading } = useColorMotiveAPI(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.COLOR_PALETTE),
      false,
      true,
      (response) => {
        setColorPalette(response?.data?.color_palette ?? "");
      },
  );

  const { isLoading: isMatchesLoading } = useColorMotiveAPI(
    () =>
      getCMApiCallJobData(jobID, CMApiCallJobData.MATCHES),
      false,
      true,
      (response) => {
        setMatches(response?.data?.matches ?? "");
      },
  );

  const fetchWordsToPalettes = async(matches) => {
    let w = '';
    w = matches?.map((match, index) => {
      let words = '';
      words += match[0];
      if (index < matches.length - 1) {
        words += ',';
      }      
    });
    try {
      const response = await api.get('/cmtools/wordsToPalettes?words=' + w + '&paletteCount=1');
      setSimilarPalettes(response);
    } catch(err) {
      console.log(err);
    }
  }

  useEffect( () => {
    fetchWordsToPalettes(matches);
  }, [matches])

  const {
    error,
    executeApiCall: submitEmail,
    isLoading: isSubmittingEmail,
  } = useColorMotiveAPI(
    postEmail,
    // run immediately
    false,
    // run on initial load
    false,
    (response) => {
      setEmailMessage(response.data.msg);
      handleModalOpen();
    },
  );

  const { isLoading: isLoadingReport } = useColorMotiveAPI(
    () => getCMApiCallJobData(jobID, CMApiCallJobData.REPORT),
    // run immediately
    false,
    // run on initial load
    true,
    (response) => {
      setReport(response?.data);
    },
  );

 const handleSubmit = useCallback(
    async (event: SyntheticEvent<HTMLFormElement>): Promise<void> => {
      event.preventDefault();
      if (hasValidationError) {
        return;
      }
      GAEventLogger.logEvent(
        GAEventCategories.RESULTS,
        GAEventActions.BUTTON_CLICK,
        {
          button: "GET_REPORT_BUTTON",
        },
      );
      submitEmail();
      await ((new HubSpotLogger()).upsertContact(hubSpotData));
    },
    [hasValidationError, hubSpotData, submitEmail],
  );

  const logModalCloseEvent = useCallback(
    () =>
      GAEventLogger.logEvent(
        GAEventCategories.RESULTS,
        GAEventActions.BUTTON_CLICK,
        {
          button: "CLOSE_MODAL",
        },
      ),
    [],
  );

  const onBlur = useCallback(
    (event: SyntheticEvent<HTMLInputElement>): void => {
      const { name, value } = event.currentTarget;
      !formConfig[name].isValid(value) &&
        setFormErrors({ ...formErrors, [name]: formConfig[name].label });
      // Log that user entered value in text input in Google Analytics
      GAEventLogger.logEvent(
        GAEventCategories.RESULTS,
        GAEventActions.USER_INPUT,
        {
          [name]: value,
        },
      );
    },
    [formErrors],
  );

  const onChange = useCallback(
    (event: SyntheticEvent<HTMLInputElement>): void => {
      const { name, value } = event.currentTarget;
      // if there is an existing error, clear it
      if (formErrors[name]) {
        setFormErrors((prevErrors: CMLeadInfo): CMLeadInfo => {
          const newFormErrors = { ...prevErrors };
          delete newFormErrors[name];
          return newFormErrors;
        });
      }
      setFormValues({ ...formValues, [name]: value.trim() });
    },
    [formErrors, formValues],
  );

  useEffect(() => {
    if (error || hasValidationError) {
      // Log that user was shown an error message in Google Analytics
      GAEventLogger.logEvent(
        GAEventCategories.RESULTS,
        GAEventActions.DISPLAY,
        {
          error: error?.message ?? `Invalid ${formErrorKeys.join(", ")}`,
        },
      );
    }
  }, [error, formErrorKeys, formErrors, hasValidationError]);

  return (
    <ThemeProvider theme={theme}>
      <Head />
      <CMHeader className={classes.header} />
      <Container className={classes.pageWidth}>
        <Typography className={classes.bottomSpacing} component="h2" variant="h2">
          Your Results
        </Typography>
        <Typography variant="body1">
          First impressions matter.<br />
          Below is the analysis of what users first see when landing on your website.
        </Typography>
        <Box className={classes.screenshot}>
          <CMImage alt="Website snapshot" isLoading={isScreenshotLoading} src={screenshot} />
          <Typography className={classes.caption} variant="body2">
            {websiteURL}
          </Typography>
        </Box>
        <hr className={classes.rule} />
        <Typography className={classes.bottomSpacing} component="h3" variant="h3">
          Colors and Emotions
        </Typography>
        <Typography className={classes.bottomSpacing} variant="body1">
          A website’s choice of color is a fundamental element that reinforces a brand’s personality and the qualities of the products and services it offers.
        </Typography>
        <Box className={classes.centerMobileOnly}>
          <Typography className={classes.subHeading} variant="subtitle1">
            Original Image Color Palette
          </Typography>
        </Box>
        <Grid className={classes.palette} container>
          {colorPalette?.map(color => {
            let hex = color.replace(/[^a-zA-Z0-9 ]/g, "");
            let brightness = parseInt(hex, 16);
            return (
              <Grid
                item
                key={hex}
                md={2}
                xs={4}>
                <Box className={`${classes.swatchColor} ${enough(brightness,15700000,classes.swatchBright)}`} style={{backgroundColor: color}}>
                </Box>
                <Typography className={classes.swatchNum} variant="caption">
                  {color}
                </Typography>
              </Grid>
            );
          })}
        </Grid>
        <Typography className={classes.subHeading} variant="subtitle1">
          Associated Emotions Matches
        </Typography>
        <Typography className={classes.bottomSpacing} variant="body1">
          According to Color Psychology research, the colors we found are associated with the following emotions:
        </Typography>
        <Box className={classes.adjectives}>
          <Grid container>
            {matches?.map((match, index) => {
              let perc = match[1] * 100;
              return (
                <Grid
                  item
                  key={index}
                  sm={6}
                  xs={12}>
                  <Typography className={classes.match} variant="h6">
                    {match[0]} <span style={{fontWeight: '100', margin: '0 10px'}}>|</span> {perc.toFixed(2)}%
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
        <Typography className={classes.subHeading} component="h3" variant="subtitle1">
          Similar Color Palette Matches
        </Typography>
        <Typography className={classes.bottomSpacing} variant="body1">
          Color can impact customer experience, influence perceptions, and sway decisions. See how strongly these palette matches reflect the emotions you want to convey. 
        </Typography>
        <Grid className={classes.similarities} container>
          {similarPalettes?.data?.palettes?.map((similarity, index) => (
            <Grid
              item
              key={index}
              md={6}
              xs={12}>
              <Box className={classes.similarity}>
                <Typography className={classes.subHeading} variant="subtitle1">
                  {similarity.word}
                </Typography>
                <Grid container>
                  {similarity.palettes[0].map(color => {
                    let hex = color.replace(/[^a-zA-Z0-9 ]/g, "");
                    let brightness = parseInt(hex, 16);
                    return (
                    <Grid
                      item
                      key={hex}
                      xs={4}>
                      <Box className={`${classes.swatchColor} ${enough(brightness,15700000,classes.swatchBright)}`} style={{backgroundColor: color}}>
                      </Box>
                      <Typography className={classes.swatchNum} variant="caption">
                        {color}
                      </Typography>
                    </Grid>
                  )})}
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container className={classes.altSection}>
        <Grid className={classes.cta} container>
          <Grid item md={4} xs={12}>
            <Box pb="24px">
              <Typography className={classes.bottomSpacing} variant="h4">
                Does your website need to communicate a different message?
              </Typography>
              <Typography className={classes.bottomSpacing} variant="subtitle2">
                The ColorMotive tool can suggest a palette of colors based on the emotions your brand should represent.
              </Typography>
            </Box>
          </Grid>
          <Grid item md={8} xs={12}>
            <Box className={classes.form}>
              <Typography variant="h6">
                Validate your color choices early in the design process
              </Typography>
              <Typography className={classes.bottomSpacing} variant="subtitle2">
                Get in touch to schedule a demo.
              </Typography>
              <Box
                component="form"
                className={classes.fields}
                maxWidth={maxContentWidth}
                mx="auto"
                onSubmit={handleSubmit}>
                <Grid container spacing={3}>
                  {Object.keys(formConfig).map((key) => {
                    const hasError = formErrorKeys.includes(key);
                    return (
                      <Grid key={key} item sm={6} xs={12}>
                        <TextField
                          error={hasError}
                          fullWidth
                          label={hasError ? formConfig[key].label : undefined}
                          name={key}
                          onBlur={onBlur}
                          onChange={onChange}
                          placeholder={formConfig[key].placeHolder}
                          value={formValues[key]}
                          variant="outlined" />
                      </Grid>
                    );
                  })}
                  <Grid container item md={6} justify="flex-start">
                    <Button
                      className={classes.getReportButton}
                      color="primary"
                      disabled={
                        isSubmittingEmail ||
                        hasValidationError ||
                        emailAddress === ""
                      }
                      fullWidth
                      type="submit"
                      variant="contained">
                      Get Report
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Grid>
        </Grid>
        {/**
         * CMModal should be the last child for proper z-order
         */}
        <CMModal
          description={
            <Typography id="modal-description">{emailMessage}</Typography>
          }
          onComplete={logModalCloseEvent}
          title={
            <Typography component="h2" id="modal-title">
              Success
            </Typography>
          }
        />
      </Container>
    </ThemeProvider>
  );
};

export default Results;
