// @flow
// Util methods for storing lead information in HubSpot

import axios from "axios";
import CMEnvironment from '../environment';

type HubSpotProperty = {|
  property: string,
  value: string,
|};

export type HubSpotContactData = $ReadOnly<{|
  adjectives_selected: string,
  adjectives_shown: string,
  email: string,
  first_name: string,
  job_id: string,
  last_name: string,
  phone_number?: string, 
  url_analyzed: string,
|}>;

const HUBSPOT_API_ROUTES: {[string]: (string => string)} = {
  UPSERT_CONTACT: (email: string): string => `https://api.hubapi.com/contacts/v1/contact/createOrUpdate/email/${email}`,
};

/**
 * @param {string} route  HubSpot API route
 * @return {string}  route appended with a query param for the HubSpot API Key
 */
const appendRouteWithApiKey =  (route: string): string => 
  `${route}?hapikey=${process.env.GATSBY_HUBSPOT_API_KEY ?? ''}`;

/**
 * Class that handles logging data from ColorMotive to HubSpot using it's API
 */
export default class HubSpotLogger {
  shouldLogToHubSpot: boolean;

  constructor() {
    this.shouldLogToHubSpot = CMEnvironment.isProd();
  }

  async upsertContact(
    data: HubSpotContactData,
  ): Promise<void> {
    if (!this.shouldLogToHubSpot) {
      return;
    }

    try {
      // Map over data and construct array of HubSpot properties
      const properties: Array<HubSpotProperty> = 
        Object.entries(data)
          .map(([key: string, value: string]): HubSpotProperty => 
            ({
              "property": key,
              "value": String(value ?? ''),
            })
          );
      const options = {
        headers: {'Content-Type': 'application/json'}
      };
      const upsertContactRoute = appendRouteWithApiKey(
        HUBSPOT_API_ROUTES.UPSERT_CONTACT(data.email),
      );
      axios.post(upsertContactRoute, {
        "properties": properties,
        "json": true,
      }, options);
    }
    catch(error) {
      console.error('Error posting data to HubSpot:::', error);
    }
  }
};