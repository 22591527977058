// @flow


import type { HubSpotContactData } from "./HubSpotLogger";
import type { CMLeadInfo } from "../../pages/results";

import CMEnviroment from "../environment";
import HubSpotLogger from './HubSpotLogger';
import { CMContext } from "../../../provider";
import { useCallback, useContext, useEffect, useState } from "react";

// Custom hook to get data from the CM app and send it to HubSpot
export const useCMToHubSpotConnector = (
  leadInfo: CMLeadInfo,
): ({
  hubSpotData: HubSpotContactData,
}) => {

  const {
    cm_api_info: cmApiInfo,
    form_info: formInfo,
  } = useContext(CMContext).state;

  const hubSpotData: HubSpotContactData = {
    adjectives_shown: formInfo.adjectives_shown.join(", "),
    adjectives_selected: formInfo.adjectives_selected.join(", "),
    email: leadInfo.emailAddress ?? "",
    first_name: leadInfo.firstName ?? "",
    job_id: cmApiInfo.job_id ?? "",
    last_name: leadInfo.lastName ?? "",
    phone_number: leadInfo.phoneNumber,
    url_analyzed: formInfo.website_url ?? "",
  };

  return ({hubSpotData});

}